.cvBackground {
  background-color: #FEFEFE;
  padding: 0px 20px;
  opacity: 0.95;
}
.cvDefaultText {
  font-family: "RO Sans",Calibri,sans-serif;
  font-size: 1.125rem;
}
.gutterBox {
  padding: 0px 2px;
}
.dot {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #04d104;
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 0 1px #03b403, 0 0 2px #03b403, 0 0 3px #03b403, 0 0 4px #03b403, 0 0 5px #03b403, 0 0 6px #03b403, 0 0 7px #03b403;
  }
  to {
    box-shadow: 0 0 2px #00d600, 0 0 4px #00d600, 0 0 6px #00d600, 0 0 8px #00d600, 0 0 10px #00d600, 0 0 12px #00d600, 0 0 14px #00d600;
  }
}